@import url('https://fonts.googleapis.com/css?family=Crimson+Text');

hr {
  border: none;
  height: 2px;
  color: #d70026;
  background-color: #d70026;
}

.title {
  font-size: 50px;
  color: #d70026;
  font-family: 'Crimson Text', serif;
}

.body {
  font-size: 32px;
  color: #000b29;
  font-family: 'Crimson Text', serif;
}

.about {
  animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.angle {
  grid-row: 6 / 7;
  color: #d70026;
  animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.0s both;

}

@keyframes text-focus-in {
  0% {
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}
