hr {
  border: none;
  height: 2px;
  color: #000b29;
  background-color: #d70026;
}

.header {
  display: flex;
  justify-content: left;
  grid-row: 1;
  grid-column: 1 / -1;
  color: #d70026;
  text-align: starts;
}

.project {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap
}

@media only screen and (max-width: 1023px) {
  .header {
    display: flex;
    justify-content: center;
  }
  
}
