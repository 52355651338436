#HomePage {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.one {
  grid-row: 1 / 8;
  grid-column: 2 / 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.two {
  grid-row: 8 /17;
  grid-column: 2 / 5;
}

.three {
  grid-row: 17/20;
  grid-column: 2 / 5;
}

.one.angle {
  grid-row: 6 / 7;
}
