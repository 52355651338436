@import url(https://fonts.googleapis.com/css?family=PT+Serif);
@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'PT Serif', serif;
}

#HomePage {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.one {
  grid-row: 1 / 8;
  grid-column: 2 / 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.two {
  grid-row: 8 /17;
  grid-column: 2 / 5;
}

.three {
  grid-row: 17/20;
  grid-column: 2 / 5;
}

.one.angle {
  grid-row: 6 / 7;
}

hr {
  border: none;
  height: 2px;
  color: #d70026;
  background-color: #d70026;
}

.title {
  font-size: 50px;
  color: #d70026;
  font-family: 'Crimson Text', serif;
}

.body {
  font-size: 32px;
  color: #000b29;
  font-family: 'Crimson Text', serif;
}

.about {
  -webkit-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.angle {
  grid-row: 6 / 7;
  color: #d70026;
  -webkit-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.0s both;
          animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.0s both;

}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

hr {
  border: none;
  height: 2px;
  color: #000b29;
  background-color: #d70026;
}

.header {
  display: flex;
  justify-content: left;
  grid-row: 1;
  grid-column: 1 / -1;
  color: #d70026;
  text-align: starts;
}

.project {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap
}

@media only screen and (max-width: 1023px) {
  .header {
    display: flex;
    justify-content: center;
  }
  
}

.Project {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.project-title {
  text-align: left;
  color: #000b29;
}
.project-details {
  color: #000b29;
  text-align: left;
}

.media-body {
  margin-left: 3em;
}

.image-link {
  box-shadow: 2px 2px 2px #000b29;
}

.image-link:hover {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}

@media only screen and (max-width: 1023px) {
  .media-body {
    margin-left: 0em;
  }
  .project-title {
    text-align: center;
  }
  .image-link {
    margin-bottom: 5em;
    align-self: center;
  }
}
@media only screen and (min-width: 1024px) {
  .Project {
    flex-direction: row;
  }
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.Contact {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.github {
  grid-row: 2;
  grid-column: 1 / 1;
}
.angellist {
  grid-row: 2;
  grid-column: 2 / 2
}
.linkedin {
  grid-row: 2;
  grid-column: 3 / 3;
}
.resume {
  grid-row: 2;
  grid-column: 4 / 4;
  align-self: auto;
}

.contactIcon {
  color: #d70026;
  width: 30px;
}

.contactLink:hover {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

